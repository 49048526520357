.gspinner .multi-ripple {
  width: 4.6rem;
  height: 4.6rem;
  margin: 2rem;
}

.gspinner .multi-ripple div {
  position: absolute;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.3rem solid #E60078;
  animation: 1.5s ripple infinite;
}

.gspinner .multi-ripple div:nth-child(2) {
  animation-delay: 0.5s;
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

.gspinner .spinner {
  position: absolute;
  height: 60px;
  width: 60px;
  border: 3px solid transparent;
  border-top-color: #E60078;
  top: 50%;
  left: 50%;
  margin: -30px;
  border-radius: 50%;
  animation: spinner 2s linear infinite;
}

.gspinner .spinner:before,
.gspinner .spinner:after {
  content: '';
  position: absolute;
  border: 3px solid transparent;
  border-radius: 50%;
}

.gspinner .spinner:before {
  border-top-color: #E60078;
  top: -12px;
  left: -12px;
  right: -12px;
  bottom: -12px;
  animation: spinner 3s linear infinite;
}

.gspinner .spinner:after {
  border-top-color: #E60078;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  animation: spinner 4s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
